import axios from "@/axios";

export default {
  fetchOnboardingRequestsList({ commit }, data) {
    const searchparam = data.searchParam;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchparam).toString();
      axios
        .get("admin/onboardingAdmin/onboardingRequestsList/?" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOnboardDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/onboardingAdmin/getdetail/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOnboardReviewDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/onboardingAdmin/getreviewdetail/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveSupportingFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("onboarding/saveSupportFile", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  statusUpdate({ commit }, data) {
    

    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/statusUpdate`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendIdVerificationRequest({ commit }, data) {
    

    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/sendIdVerificationRequest`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addBeneficiaryOwner({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/addOwner/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addAuthorisedSignatory({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/addSignature/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addBankAccount({ commit }, { id, data, config }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/addBank/${id}`, data, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPrimaryContact({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/addContact/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  deleteOnboardingForm({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/onboardingAdmin/deleteOnboardingForm", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },




  deleteBeneficiaryOwner({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/deleteOwner/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteAuthorisedSignatory({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/deleteSignature/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteBankAccount({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/deleteBank/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deletePrimaryContact({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/deleteContact/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendOnboardingRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/onboardingRequest`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  calculateOnPayLater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("calculation", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTillWebhookList({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/onboardingAdmin/getTillWebhookList" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  resolveNoMatchIssue({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("admin/onboardingAdmin/resolveNoMatch", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  generateCSV({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/onboardingAdmin/generateCSV/"+data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  markAsSendStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/onboardingAdmin/markAsSendStatus/"+data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  //update status
  linkIntroducerToMerchant({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingAdmin/linkIntroducerToMerchant`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //create draft merchant
  createDraftMerchant({ commit }, data) {
    

    return new Promise((resolve, reject) => {
      axios
        .get(`admin/onboardingAdmin/`+data.onboardingRequestId+'/'+data.onboardingId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  checkMerchantEmailValidityAdmin({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/onboardingAdmin/is-valid-merchant-email/${email}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createOnboardingProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/onboardingProfile`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getOnboardingProfileDetail({ commit }, profileId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/onboardingProfile/${profileId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateOnboardingProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`admin/onboardingProfile/${data.profileId}/update`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  abnLookUpAction({ commit }, abn) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/onboarding/search-abn-to-autocomplete?search=${abn}`)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },



  saveGeneralInfo({ commit }, data) {
    

    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/onboardingAdmin/saveGeneralInfo`,data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  retryProvisioning({ commit }, data) {

    return new Promise((resolve, reject) => {
      axios
        .post(`/till-onboard/retry-provisioning`,data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  resendOnboardingLinkToMerchant({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/onboardingAdmin/resendOnboardingLink`,data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendBackOnboard({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/onboardingAdmin/sendBack`,data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  updateOnboardingUserInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`admin/onboardingAdmin/updateOnboardingInfo/${payload.param}`, payload.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

}
