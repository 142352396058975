/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
  storeTransaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("transactions", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  exportTransactionListWithFilter({ commit }, data) {
    const searchparam = data.searcParam;
    const params = new URLSearchParams(searchparam).toString();
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/transaction/export/?"+params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTransactionListWithFilter({ commit }, data) {
    const params = new URLSearchParams(data).toString();
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/transaction/?" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTransactionListByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("transactions/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
