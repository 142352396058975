/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

import moduleAdmin from "./admin/moduleAdmin.js";
import moduleEmail from "./email/moduleEmail.js";
import moduleSms from "./sms/moduleSms.js";
import moduleAuth from "./auth/moduleAuth.js";
import modulePage from "./page/modulePage.js";
import moduleUser from "./user-management/moduleUserManagement.js";
import moduleTransaction from "./transaction/moduleTransaction.js";
import modulePayment from "./payment/modulePayment";
import modulePaymentRequest from "./paymentRequest/modulePaymentRequest";
import modulePaymentType from "./paymentType/modulePaymentType";
import moduleBank from "./bank/moduleBank";
import moduleCompany from "./company/moduleCompany";
import modulePartner from "./partner/modulePartner";
import moduleCard from "./cards/moduleCard";
import moduleConnector from "./connector/moduleConnector";
import moduleLegal from "./legals/moduleLegal";
import modulePaymentPlans from "./paymentplans/modulePaymentPlans";
import moduleListSetting from "./listSettings/moduleListSetting";
import moduleNotificationVerify from "./notificationVerify/moduleNotificationVerify";
import moduleIntroducer from "./introducer/moduleIntroducer";
import moduleOnboardingAdmin from "./onboarding-admin/moduleOnboardingAdmin";
import moduleFlipBankAccounts from "./flipBankAccounts/moduleFlipBankAccounts";
import moduleOnboardingProfile from "./onboardingProfile/moduleOnboardingProfile";
import moduleS3Service from "./s3Service/moduleS3Service";
import moduleNote  from "./note/moduleNote";
import moduleSetting from "./setting/moduleSetting";
import moduleExport from "./export/moduleExport"
import moduleDisbursement from "./disbursement/moduleDisbursement";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    admin: moduleAdmin,
    page: modulePage,
    user: moduleUser,
    paymentRequest: modulePaymentRequest,
    transaction: moduleTransaction,
    payment: modulePayment,
    paymentType: modulePaymentType,
    bank: moduleBank,
    company: moduleCompany,
    partner: modulePartner,
    connector: moduleConnector,
    card: moduleCard,
    legals: moduleLegal,
    paymentPlans: modulePaymentPlans,
    listSetting: moduleListSetting,
    email: moduleEmail,
    sms: moduleSms,
    notificationVerify: moduleNotificationVerify,
    introducer: moduleIntroducer,
    onboardingAdmin: moduleOnboardingAdmin,
    flipBankAccounts: moduleFlipBankAccounts,
    onboardingProfile: moduleOnboardingProfile,
    s3ServiceModule: moduleS3Service,
    note: moduleNote,
    setting: moduleSetting,
    dataExport: moduleExport,
    disbursement: moduleDisbursement,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [createPersistedState()],
});

