import axios from "../../http/axios/index.js"

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },

  GET_ALL_PLANS(state, payload) {
    state.paymentPlans = payload;
  },

  DELETE_PLAN(state, payload) {
    state.paymentPlans = state.paymentPlans.filter(item => item._id !== payload);
  },

  ADD_PLAN(state, payload) {
    state.paymentPlans.push(payload);
  },

  UPDATE_PLAN(state, payload) {
    const index = state.paymentPlans.findIndex(topic => topic._id === payload._id);

    if (index !== -1) {
      state.paymentPlans.splice(index, 1, payload);
    }
  },

  SET_CURRENT_PLAN(state,payload) {
    state.currentPlan = payload;
  },

  SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(state, payload) {
    state.manualAssessmentCriteria = payload;
  },

  UNSET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(state) {
    state.manualAssessmentCriteria = "";
  },

  ADD_LEGALS(state, payload) {
    state.currentPlan.legals.push(payload);
  },

  DELETE_LEGALS(state) {
    state.currentPlan.legals.pop();
  },

  UPDATE_LEGALS(state, payload) {
    const index = state.currentPlan.legals.findIndex(topic => topic._id === payload._id);

    if (index !== -1) {
      state.currentPlan.legals.splice(index, 1, payload);
    }
  },

  ADD_INTEGRATION_GUIDE(state, payload) {
    state.currentPlan.integrationGuide = payload;
  },

  DELETE_INTEGRATION_GUIDE(state, payload) {
    state.currentPlan.integrationGuide = {};
  },

  SET_EXTRA_CONDITIONS(state, payload){
    state.currentExtraCondtions = payload;
  },

  UNSET_EXTRA_CONDITIONS(state, payload){
    state.currentExtraCondtions = '';
  },

  SET_PRODUCT_FIELDS(state, payload) {
    state.productCustomFields = payload;
  },

  UNSET_PRODUCT_FIELDS(state, payload) {
    state.productCustomFields = [];
  },

  SET_PAYMENT_METHOD_INFO(state, payload) {
    state.paymentMethod = payload;
  },

  UNSET_PAYMENT_METHOD_INFO(state, payload) {
    state.paymentMethod = "";
  },
};
