import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  fetchCardList({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/cards/"+ params)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchAllCards({commit}){
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/cards/all")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchCardDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/cards/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCardDetailsById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/admin/cards/" + data.id, data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCardDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/cards", data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCard({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/admin/cards/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deletePartnerCard({commit}, id){
    return new Promise((resolve, reject) => {
      axios
        .delete("/admin/cards/partnerCards/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /*fetchPartners({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/partners" + params)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchPartnerDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/partners/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  storePartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/partners", data.data, data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  updatePartnerById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/admin/partners/" + data.id, data.data, data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  }*/
};
