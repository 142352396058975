export default {
  getPaymentPlanById: state => id => {
    return state.paymentPlans.find(plan => plan._id === id);
  },

  getCurrentPaymentPlan: state => {
    return state.currentPlan;
  },

  getCurrentManualAssessmentCriteria: state => {
    return state.manualAssessmentCriteria;
  },

  getCurrentExtraConditions: state => {
    return state.currentExtraCondtions;
  },

  GET_PRODUCT_FIELDS: state => {
    return state.productCustomFields;
  },

  getPaymentMethod: state => {
    return state.paymentMethod;
  },
};
